import React from "react"
import { useState } from "react"
import { Button, Col, Form, Modal } from "react-bootstrap"
import { SuccessRocket } from "../animation/SuccessRocket"

export const DemoModal = props => {
  const [status, setStatus] = useState("")
  const [selectedProduct, setSelectedProduct] = useState("")
  const { onHide } = props

  const productOnChange = event => {
    if (event.target.value === "N/A") setSelectedProduct("")
    else setSelectedProduct(event.target.value)
  }

  const formSubmitter = ev => {
    ev.preventDefault()
    const form = ev.target

    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
    }
    xhr.send(data)
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={status !== "SUCCESS"}>
        {status !== "SUCCESS" && (
          <h1 className="text-left pt-3">REQUEST A DEMO</h1>
        )}
      </Modal.Header>

      <Modal.Body>
        {status === "SUCCESS" ? (
          <>
            <SuccessRocket />
            <Button
              className="mt-5 close-modal-button"
              onClick={() => onHide()}
            >
              Back To Site
            </Button>
          </>
        ) : (
          <>
            <p
              className="text-left contact-excerpt pt-3"
              style={{ letterSpacing: "0px" }}
            >
              Reach out and request your free, personalized demo today.
            </p>
            <Form
              className="pt-4"
              onSubmit={formSubmitter}
              action="https://formspree.io/f/mbjpgqvj"
              method="POST"
            >
              <Form.Row className="pb-2">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Control name="name" placeholder="Name" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmaila">
                  <Form.Control name="email" type="email" placeholder="Email" />
                </Form.Group>
              </Form.Row>

              <Form.Row className="pb-2">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Control
                    name="telephone"
                    type="telephone"
                    placeholder="Phone"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridProduct">
                  <Form.Control
                    as="select"
                    name="product-interested"
                    defaultValue="N/A"
                    onChange={productOnChange}
                    className={selectedProduct ? "" : "empty-select"}
                  >
                    <option value="N/A">
                      Product Interested In (Optional)
                    </option>
                    <option value="BestBuy And Dispatch">
                      Bestbuy And Dispatch
                    </option>
                    <option value="Online Selling Platform">
                      Online Selling Platform
                    </option>
                    <option value="Customer Portal">Customer Portal</option>
                    <option value="Pricing Engine">Pricing Engine</option>
                    {/* <option value="Lease Crude Optimization ">
                      Lease Crude Optimization
                    </option> */}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Group className="pb-2" controlId="fomrGridMessage">
                <Form.Control
                  name="message"
                  as="textarea"
                  placeholder="Message"
                />
              </Form.Group>

              <div className="button-container">
                <Button variant="primary" type="submit">
                  Send Request
                </Button>

                {status === "ERROR" && <p>Ooops! There was an error.</p>}
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
