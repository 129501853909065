import { Col, Row } from "react-bootstrap"
import React from "react"
import Lottie from "react-lottie"
import * as animationData from "./form_success.json"
import { useEffect } from "react"
import { useState } from "react"

export const SuccessRocket = () => {
  const [res, setRes] = useState(200)
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.screen.width > 768) {
        setRes(400)
      }
    }
  }, [])
  return (
    <div className="loading-container">
      <Row>
        <Col>
          <Lottie
            options={defaultOptions}
            height={res}
            width={res}
            isStopped={false}
            isPaused={false}
          />
        </Col>
      </Row>
      <h1 className="option-loading-message">
        Thank you for your request. We'll be in touch <b>ASAP!</b>
      </h1>
    </div>
  )
}
