import React from "react"
import { TransitionLink } from "../animation/TransitionLink"
import { Col, Row, Container, Image } from "react-bootstrap"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import bbd_logo from "../../images/logos/bbd_logo.png"
export const SolutionMenu = ({ show, handleLeave }) => {
  const showClass = show ? "show" : ""
  return (
    <div
      onMouseLeave={handleLeave}
      className={`solutions-menu text-left solution ${showClass}`}
    >
      <Container fluid className="mega-menu-container solutions">
        <Row>
          <Col lg={4}>
            <div
              style={{
                backgroundColor: "var(--navy-site-bg)",
              }}
              className="achievement-card featured-solution"
            >
              <div className="flex-1">
                <h3
                  className="pb-3"
                  style={{ color: "white", fontSize: "2.25em" }}
                >
                  best plan, best route, best buy.
                </h3>
              </div>
              <div
                className="flex-1 text-left"
                style={{ color: "var(--gray-200)" }}
              >
                Gravitate Best Buy is an integrated supply optimization and
                dispatch solution designed to optimize store supply decisions
                and manage logistics for the gas retail industry.
              </div>

              <div className="flex-2">
                <div className="pt-4">
                  <TransitionLink
                    to="/solution/bestbuy_dispatch"
                    className="custom-underline bbd"
                  >
                    LEARN MORE
                    <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
                  </TransitionLink>
                </div>
              </div>
              <div className="flex-1">
                <Image
                  fluid
                  className="pt-3"
                  style={{ width: "72%" }}
                  src={bbd_logo}
                />
              </div>
            </div>
          </Col>
          <Col lg={8} className="solution-link-container">
            <Row className="w-100">
              <Col lg={4}>
                <TransitionLink className="bbd" to="/solution/bestbuy_dispatch">
                  BestBuy and Dispatch
                </TransitionLink>
                <p className="mt-2 pr-3">
                  An integrated supply optimization and dispatch solution.
                </p>
              </Col>
              <Col lg={4}>
                <TransitionLink
                  className="osp"
                  to="/solution/online_selling_platform"
                >
                  Online Selling Platform
                </TransitionLink>
                <p className="mt-2 pr-3">
                  Self-service online selling of refined products with real-time
                  price basis.
                </p>
              </Col>
              <Col lg={4}>
                <TransitionLink className="pe" to="/solution/pricing_engine">
                  Pricing Engine
                </TransitionLink>
                <p className="mt-2 pr-3">
                  Automation of complex price calculations and communication
                  with customers.
                </p>
              </Col>
            </Row>
            <Row className="w-100">
              <Col lg={4}>
                <TransitionLink className="cp" to="/solution/customer_portal">
                  Customer Portal
                </TransitionLink>
                <p className="mt-2 pr-3">
                  Share transaction data from your ETRM system (RightAngle, SAP,
                  Allegro) directly to your customers.
                </p>
              </Col>
              <Col lg={4}></Col>
              <Col lg={4}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
