import React from "react"
import { TransitionLink } from "../animation/TransitionLink"
import { Col, Row, Container } from "react-bootstrap"
export const MarketMenu = ({ show, handleLeave }) => {
  const showClass = show ? "show" : ""
  return (
    <div
      onMouseLeave={handleLeave}
      className={`markets-menu text-left ${showClass}`}
    >
      <Container className="mega-menu-container">
        <Row className="mb-3">
          <Col lg={6}>
            <h6>Refined Products</h6>
          </Col>

          <Col lg={3}>
            <h6>NGL's</h6>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <TransitionLink to="/refined_products/wholesalers">
              Wholesalers
            </TransitionLink>
            <p className="mt-2 pr-3">
              Refined Products Wholesalers struggle with high touch business
              processes, dynamic prices, and competitive online business.
            </p>
          </Col>
          <Col lg={3}>
            <TransitionLink to="/refined_products/c_stores">
              C-Stores
            </TransitionLink>
            <p className="mt-2 pr-3">
              C-Store Operators face variable demand patterns, complex supply
              arrangements, route planning and logistics challenges.
            </p>
          </Col>
          <Col lg={3}>
            <TransitionLink to="/ngls/wholesalers">Wholesalers</TransitionLink>
            <p className="mt-2 pr-3">
              NGL Wholesalers often battle with high-touch order management
              processes, enabling customer self-service, and high administrative
              costs.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
