import { faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Button, Col, Collapse, Row } from "react-bootstrap"
import { TransitionLink } from "../animation/TransitionLink"
import { DemoModalContext } from "../demo-modal-context"

export const MobileMenu = props => {
  const showClass = props.show ? "show" : ""
  const { linkClick, hideMenu } = props
  return (
    <div className={"mobile-menu " + showClass}>
      <div className="position-relative h-100">
        <nav className="nav-inner" id="menu">
          <div className="link-container">
            <div>
              <MarketExpand linkClick={linkClick} title="Markets" />
            </div>
            <div>
              <SolutionExpand linkClick={linkClick} title="Solutions" />
            </div>
            <div>
              <DirectLink
                linkClick={linkClick}
                to="/insights"
                title="Insights"
              />
            </div>
            <div>
              <DirectLink
                linkClick={linkClick}
                to="/contact"
                title="Contact Us"
              />
            </div>
            <div>
              <DirectLink
                  linkClick={linkClick}
                  to="https://boards.greenhouse.io/gravitate"
                  title="Careers"
              />
            </div>
          </div>
          <div className="mobile-cta">
            <DemoModalContext.Consumer>
              {({ showModal, toggleModal }) => (
                <Button
                  onClick={() => {
                    hideMenu(false)
                    toggleModal()
                  }}
                  style={{ backgroundColor: "var(--theme-2)" }}
                >
                  <h6 className="mb-0">Request A Demo</h6>
                </Button>
              )}
            </DemoModalContext.Consumer>
          </div>
        </nav>
      </div>
    </div>
  )
}
function MarketExpand({ title, linkClick }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="text-left link-group-header"
      >
        <Row>
          <Col sm={10} xs={10}>
            <h6> {title}</h6>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon={faPlus} />
          </Col>
        </Row>
      </Button>
      <Collapse in={open}>
        <div>
          <TransitionLink
            beforeFunction={linkClick}
            to="/refined_products/wholesalers"
          >
            <p className="sub-link text-left mt-4 pl-3">
              Refined Product Wholesalers
            </p>
          </TransitionLink>
          <TransitionLink
            beforeFunction={linkClick}
            to="/refined_products/c_stores"
          >
            <p className="sub-link text-left mt-4 pl-3"> C-Stores</p>
          </TransitionLink>
          <TransitionLink beforeFunction={linkClick} to="/ngls/wholesalers">
            <p className="sub-link text-left mt-4 pl-3">NGL Wholesalers</p>
          </TransitionLink>
         
          {/* <TransitionLink
            beforeFunction={linkClick}
            to="/crude/first_producers"
          >
            <p className="sub-link text-left mt-4 pl-3">
              Crude First Purchasers
            </p>
          </TransitionLink> */}
        </div>
      </Collapse>
    </>
  )
}
function SolutionExpand({ title, linkClick }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="text-left link-group-header"
      >
        <Row>
          <Col sm={10} xs={10}>
            <h6> {title}</h6>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon={faPlus} />
          </Col>
        </Row>
      </Button>
      <Collapse in={open}>
        <div>
          <TransitionLink
            beforeFunction={linkClick}
            className="bbd"
            to="/solution/bestbuy_dispatch"
          >
            <p className="sub-link text-left mt-4 pl-3">BestBuy and Dispatch</p>
          </TransitionLink>
          <TransitionLink
            beforeFunction={linkClick}
            className="osp"
            to="/solution/online_selling_platform"
          >
            <p className=" sub-link text-left mt-4 pl-3">
              Online Selling Platform
            </p>
          </TransitionLink>
          <TransitionLink
            beforeFunction={linkClick}
            className="pe"
            to="/solution/pricing_engine"
          >
            <p className=" sub-link text-left mt-4 pl-3">Pricing Engine</p>
          </TransitionLink>
          <TransitionLink
            beforeFunction={linkClick}
            className="cp"
            to="/solution/customer_portal"
          >
            <p className="sub-link text-left mt-4 pl-3">Customer Portal</p>
          </TransitionLink>
          {/* <TransitionLink
            beforeFunction={linkClick}
            className="lco"
            to="/solution/lease_crude_optimization"
          >
            <p className="sub-link text-left mt-4 pl-3">
              Lease Crude Optimization
            </p>
          </TransitionLink> */}
        </div>
      </Collapse>
    </>
  )
}
function DirectLink({ title, to, linkClick }) {
  return (
    <>
      <Button className="text-left direct-link">
        <Row>
          <Col xs={10}>
            <TransitionLink beforeFunction={linkClick} to={to}>
              <h6> {title}</h6>
            </TransitionLink>
          </Col>
          <Col xs={2}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Col>
        </Row>
      </Button>
    </>
  )
}
