import React from "react"
import logo from "../../images/logo.png"
import { Link } from "gatsby"
import { TransitionLink } from "../animation/TransitionLink"
import { useState } from "react"
import { MarketMenu } from "./MarketMenu"
import { SolutionMenu } from "./SolutionMenu"
import { Button } from "react-bootstrap"
import { DemoModalContext } from "../demo-modal-context"
import { MobileMenu } from "../landing/MobileMenu"
export const HorizontalNav = ({ colorClass = "default" }) => {
  const [showMarketMenu, setShowMarketMenu] = useState(false)
  const [showSolutionMenu, setShowSolutionMenu] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const lineExpandClass = showMobileMenu ? "expanded" : ""
  const handleMarketClick = () => {
    setShowSolutionMenu(false)
    setShowMarketMenu(!showMarketMenu)
  }
  const handleSolutionClick = () => {
    setShowMarketMenu(false)
    setShowSolutionMenu(!showSolutionMenu)
  }
  const handleMobileMenuClick = () => {
    if (showMobileMenu) {
      document.body.style.overflow = "auto"
      var elem = document.querySelector("html")
      elem.style.overflow = "auto"
    } else {
      document.body.style.overflow = "hidden"
      var elem = document.querySelector("html")
      elem.style.overflow = "hidden"
    }
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <header className={`main-header ${colorClass} ${lineExpandClass}`}>
      <Link to="/" className="header-logo ajax">
        <img src={logo} alt="" />
      </Link>

      <div className="nav-button-wrap">
        <div className="nav-button desktop">
          <DemoModalContext.Consumer>
            {({ showModal, toggleModal }) => (
              <a
                onClick={() => toggleModal()}
                to="/contact"
                className="start-btn color-bg header"
              >
                <div>REQUEST A DEMO</div>
              </a>
            )}
          </DemoModalContext.Consumer>
        </div>
        <div
          onClick={() => handleMobileMenuClick()}
          className="nav-button mobile"
        >
          <span className={"nos " + lineExpandClass}></span>
          <span className={"ncs " + lineExpandClass}></span>
          <span className={"nbs " + lineExpandClass}></span>
          <div className={"menu-button-text " + lineExpandClass}>
            {showMobileMenu ? "Close" : "Menu"}
          </div>
        </div>
      </div>
      <MobileMenu
        show={showMobileMenu}
        hideMenu={setShowMobileMenu}
        linkClick={handleMobileMenuClick}
      />

      <div className="header-contacts">
        <ul>
          <li>
            <Button onClick={handleMarketClick} className="menu-item market-hover">
              Markets
            </Button>
          </li>
          <li>
            <Button onClick={handleSolutionClick} className="menu-item market-hover">
              Solutions
            </Button>
          </li>
          <li>
            <span>
              <TransitionLink to="/insights" className="menu-item market-hover">Insights</TransitionLink>
            </span>
          </li>
          <li>
            <Button onClick={() => window.open('https://boards.greenhouse.io/gravitate', '_blank')} className="menu-item market-hover">
             Careers
            </Button>
          </li>
        </ul>
      </div>
      <MarketMenu handleLeave={handleMarketClick} show={showMarketMenu} />
      <SolutionMenu handleLeave={handleSolutionClick} show={showSolutionMenu} />
    </header>
  )
}
