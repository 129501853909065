import React from "react"
import { TransitionContext } from "../../../transition-context"

export const TransitionLink = ({
  to,
  linkText,
  className,
  children,
  beforeFunction,
}) => {
  return (
    <TransitionContext.Consumer>
      {({ transitioning, toggleTransition }) => (
        <a
          href="# "
          className={className && className}
          onClick={() => {
            beforeFunction && beforeFunction()
            toggleTransition(to)
          }}
        >
          {linkText}
          {children}
        </a>
      )}
    </TransitionContext.Consumer>
  )
}
