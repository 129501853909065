import React from "react"
import PropTypes from "prop-types"
import Seo from "./seo"
import { HorizontalNav } from "./sitewide/HorizontalNav"

import "../assets/css/theme/reset.css"
import "../assets/css/main.scss"
import "../assets/css/theme/color.css"
import { PageChangeAnimate } from "./animation/PageChangeAnimate"
import { DemoModalContext } from "./demo-modal-context"
import { DemoModal } from "./sitewide/DemoModal"

const Layout = ({ children, colorClass, title, description, image }) => {
  const [showModal, setShowModal] = React.useState(false)
  const toggleModal = () => {
    setShowModal(true)
  }
  return (
    <>
      <DemoModalContext.Provider value={{ showModal, toggleModal }}>
        <PageChangeAnimate />
        <Seo title={title} description={description} image={image} />
        <HorizontalNav colorClass={colorClass} />
        <DemoModal show={showModal} onHide={() => setShowModal(false)} />
        <main>{children}</main>
      </DemoModalContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
